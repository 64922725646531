import React from "react";
import "./aboutUs.css";
import {
  Accordion,
  Hero,
  NavBar,
  ProfileCard,
  TextArea,
} from "../../components";
import { Footer } from "../../containers";

import { Helmet } from "react-helmet";

import images from "../../assets/images";

function AboutUs(props) {
  const heroDetails = { title: "About Us", image: images.mkumsaEb };
  const details = [
    {
      title: "Background",
      text: ` The Mount Kenya University Medical Students' Association (MKUMSA) is
      the local organization that accommodates the medical students of Mount
      Kenya University. Its members encompass the medical students currently
      pursuing their course and medical school alumni who are already in the
      professional line. MKUMSA is affiliated with The Medical Students'
      Association of Kenya (MSAKE) and The International Federation of Students
      Association (IFMSA). </br> </br>
      Since its inception in the year 2015. MKUMSA has championed academic
      development and excellence, community service participation and welfare
      for its members. The association has planned, undertaken and participated
      in many activities both locally and nationally. Through the association we
      foster a sense of belief and the need to take individual responsibility for
      our actions with the hope that these sentiments will mold us to become
      better healthcare providers.`,
    },

    {
      title: "Slogan",
      text: ` Nurtuting our future doctors`,
    },

    {
      title: "Vision",
      text: ` To be a world class association for future healthcare providers.`,
    },

    {
      title: "Mision",
      text: ` To empower medical students, through student-driven activities
      and collaborations, to come up with practical and innovative
      solutions to deal with medical, environmental and
      socioeconomic challenges in the society and to provide world
      class medical services in Kenya and beyond.`,
    },
    {
      title: "Objectives",
      text: `<ul>
      <li>
      To facilitate partnerships between the Mount Kenya University medical
 students' community and local, national and international organizations
 and/or associations working on health, education and social issues.
     </li>
     <li>
     To expose Mount Kenya University medical students to humanitarian and
     health issues, providing them with the opportunity to educate themselves
     and their peers.
     </li>
     <li>
     To create networks that links Mount Kenya University medical students with
     other medical training colleges and schools, both locally and internationally,
     to provide exposure and interactions
     </li>
    <li>
    To empower all Mount Kenya University medical students to become
    advocates in leading social change.
    </li>
    <li>
    To be the voice of all, Mount Kenya University Medical Students
    safeguarding their interests and welfare, collaborating with the Universities
    administration in pursuance of the same.
    </li>
    <li>
    To provide a platform through which medical students can offer innovative
    solutions and meaningful service to the society. This will be through
    among others. </li>
   <li> To foster growth and partnerships with alumni.</li>

    </li>

    </ul>`,
    },
  ];

  const leaders = [
    {
      name: "Dr. Cecilia Munguti ",
      role: "Patron",
      email: "email",
      linkedin: "linkedin",
      picture: images.patron,
    },
    {
      name: "Fredrick Mugambi",
      role: "President",
      email: "Mkumsa.president@gmail.com",
      linkedin: " ",
    },
    {
      name: "Amani Karuiru",
      role: "Vice-President",
      email: "mkumsa.vicepresident@gmail.com",
      linkedin: "https://ke.linkedin.com/in/emmanuel-riri-0a1a262a5",
      picture: images.amani,
    },

    {
      name: "Guyatu Jarso",
      role: "Secretary General",
      email: "",
      linkedin: "linkedin",
    },
    {
      name: "Khamis Ali",
      role: "Finance Director",
      email: "",
      linkedin: "",
    },
    {
      name: "ChepKirui Ascar",
      role: "Organizing Secretary",
      email: "chepkiruiascar36@gmail.com",
      linkedin:
        "https://www.linkedin.com/in/ascar-rotich-536bba2b3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      picture: images.ascar,
    },
    {
      name: "Jared kiptoo",
      role: "Academic Secretary",
      email: "kirwajared22@gmail.com",
      linkedin:
        "https://www.linkedin.com/in/jared-kirwa-76b63a270?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      picture: images.jaredKiptoo,
    },
    {
      name: "Salome Faraja Kiheo",
      role: "Public Relations",
      email: "kiheosally20@gmail.com ",
      linkedin:
        " https://www.linkedin.com/in/salome-faraja-kiheo-kaberere-142160266?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      picture: images.salomeFaraja,
    },
  ];

  // const leaders = [
  //   {
  //     name: "Dr. Cecilia Munguti ",
  //     role: "Patron",
  //     email: "email",
  //     linkedin: "linkedin",
  //     picture: images.patron,
  //   },
  //   {
  //     name: "Naomi Bett ",
  //     role: "President",
  //     email: "Mkumsa.president@gmail.com",
  //     linkedin:
  //       " https://www.linkedin.com/in/naomi-bett-10480n?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  //     picture: naomi,
  //   },
  //   {
  //     name: "Emmanuel Riri",
  //     role: "Vice-President",
  //     email: "mkumsa.vicepresident@gmail.com",
  //     linkedin: "https://ke.linkedin.com/in/emmanuel-riri-0a1a262a5",
  //     picture: riri,
  //   },

  //   {
  //     name: "Samuel Nzaka ",
  //     role: "Secretary General",
  //     email: "samuelnzaka6@gmail.com",
  //     linkedin: "linkedin",
  //     picture: nzaka,
  //   },
  //   {
  //     name: "Kelvin Maina  ",
  //     role: "Finance Director",
  //     email: "kelvin.business@outlook.com",
  //     linkedin: "https://ke.linkedin.com/in/kelvin-maina-15424313a",
  //     picture: kevin,
  //   },
  //   {
  //     name: "Dennis Kamiri ",
  //     role: "Organizing Secretary",
  //     email: "kamriedennis87@gmail.com",
  //     linkedin: "linkedin",
  //     picture: images.kamrie,
  //   },
  //   {
  //     name: "Lorraine Makhanuh ",
  //     role: "Academic Secretary",
  //     email: "makhanulorraine@gmail.com",
  //     linkedin: "",
  //     picture: lorraine,
  //   },
  //   {
  //     name: "Jemimah Joy ",
  //     role: "Public Relations",
  //     email: "hopejemi@gmail.com",
  //     linkedin: "linkedin",
  //     picture: joy,
  //   },
  // ];

  const history = {
    title: "History",
    text: `The Mount Kenya University Medical Students' Association (MKUMSA) was founded in October 2014 by the pioneer medical students of the university. The association emerged out of a recognized need for proper student leadership and representation within the School of Medicine. With guidance from esteemed professors such as Prof. Kimathi Kigatiira, students sought to create a structured organization to represent their interests and provide leadership within the school.
  </br> </br>
  Dr. Odumbe George Cammilus, a member of the pioneer class, was selected as the first President of the School of Medicine, holding the position for five years. During his tenure, he faced the initial challenge of leading the new association without a formal constitution. However, through perseverance and reflection, Dr. Odumbe transformed this challenge into an opportunity to build a solid foundation for MKUMSA. The association was officially launched under his leadership, with the induction of a leadership team and coordination of key activities such as community medical outreaches and participation in university health events.

</br> </br>

  Dr. Odumbe also served as a link between MKUMSA and the Medical Students’ Association of Kenya (MSAKE).
  </br> </br>
  Over the years, MKUMSA has grown significantly, expanding its activities and impact within the university and the surrounding communities. The association has organized a variety of programs and events, including mentorship programs, blood drives, medical camps, and awareness walks aimed at promoting health education. MKUMSA has also been instrumental in hosting key milestones for medical students, such as white-coat ceremonies, which symbolize the formal induction into the medical profession, and finalist dinners to celebrate the achievements of students completing their studies.

  </br> </br>

  `,
  };

  const previousLeaders = [
    {
      title: "2014 - 2019",
      content: ` <ul>
    <li>
      <strong>Patron</strong>-Dr. Brian Wambua
    </li>
    <li>
      <strong>President</strong>- Dr. Odumbe George Cammilus
    </li>
    <li>
      <strong>Vice - President</strong> -Dr. Mitchelle Njeri Kagotho
    </li>
    <li>
      <strong>Treasurer</strong>-Dr. Dickson Ndegwa
    </li>
    <li>
      <strong>Secretary General</strong>- Dr.Florence Simwa
    </li>

    <li>
      <strong>Academic Secretary </strong> -Dr. Joseph Wafula Siboko
    </li>
    <li>
      <strong>Representative pioneer class </strong>-Dr. Daniel Ngigi
    </li>
    <li>
      <strong>Speaker of the Congress </strong> -Dr. Charity Chepkomei
      Kirui
    </li>

    <li>
      <strong>Public relations</strong>-Dr Nicholas Koome
    </li>
  </ul>`,
    },
    {
      title: "2019 - 2020",
      content: `<ul>
    <li>
      <strong>Patron</strong> -Dr .Mbira
    </li>
    <li>
      <strong>President</strong> - Zebedee Nyakwara
    </li>
    <li>
      <strong>Vice-President</strong> - Ahmed Shenga
    </li>

    <li>
      <strong>Finance Director</strong> - Absirahman Abujey
    </li>
    <li>
      <strong>Secretary General</strong> - Dennis Wanjau
    </li>
    <li>
      <strong>Organizing Secretary</strong> -
    </li>
    <li>
      <strong>Academic secretary</strong>-
    </li>
    <li>
      <strong>Public relations</strong>- Faith Nyarangi.
    </li>
  </ul>`,
    },

    {
      title: "2020- 2021",
      content: `<ul>
    <li>
      <strong>Patron</strong> -Dr .Mbira
    </li>
    <li>
      <strong>President</strong> - Madeline
    </li>
    <li>
      <strong>Vice-President</strong> -
    </li>

    <li>
      <strong>Finance Director</strong> -
    </li>
    <li>
      <strong>Secretary General</strong> -
    </li>
    <li>
      <strong>Organizing Secretary</strong> -
    </li>
    <li>
      <strong>Academic secretary</strong>-
    </li>
    <li>
      <strong>Public relations</strong>-
    </li>
  </ul>`,
    },

    {
      title: "2021 - 2022",
      content: `
    <ul>
            <li>
              <strong>Patron</strong> -Dr .Mbira
            </li>
            <li>
              <strong>President</strong> -Dr. Dennis Wanjau
            </li>
            <li>
              <strong>Vice-President</strong> -Naomi Bett
            </li>

            <li>
              <strong>Finance Director</strong> -Bett Lynne Chepchumba
            </li>
            <li>
              <strong>Secretary General</strong> -Franscisco Raphael
            </li>
            <li>
              <strong>Organizing Secretary</strong> John Wanjau
            </li>
            <li>
              <strong>Academic secretary</strong>- Dr. Lilian Mumbi
            </li>
            <li>
              <strong>Public relations</strong>- Dr. Kauthar Salim
            </li>
          </ul>`,
    },

    {
      title: "2022 - 2023",
      content: `<ul>
    <li>
      <strong>Patron</strong> -Dr .Mbira
    </li>
    <li>
      <strong>President</strong> -Abidihakim Abdi
    </li>
    <li>
      <strong>Vice-President</strong> - Brenda Maangi
    </li>

    <li>
      <strong>Finance Director</strong> - Permute Magoma
    </li>
    <li>
      <strong>Secretary General</strong> - Michael Abuta
    </li>
    <li>
      <strong>Organizing Secretary</strong> - Lynette Kiniu
    </li>
    <li>
      <strong>Academic secretary</strong>- Kerubo Orina
    </li>
    <li>
      <strong>Public relations</strong>- Onunda Boaz
    </li>
  </ul>`,
    },

    {
      title: "2023 - 2024",
      content: `<ul>
    <li>
      <strong>Patron</strong> -Dr .Munguti
    </li>
    <li>
      <strong>President</strong> - Naomi Bett
    </li>
    <li>
      <strong>Vice-President</strong> -Emmanuel Riri
    </li>

    <li>
      <strong>Finance Director</strong> - Kelvin Maina
    </li>
    <li>
      <strong>Secretary General</strong> - samuel Nzaka
    </li>
    <li>
      <strong>Organizing Secretary</strong> - Dennis Kamiri
    </li>
    <li>
      <strong>Academic secretary</strong>- Lorraine Makhanuh
    </li>
    <li>
      <strong>Public relations</strong>- Jemimah Joy
    </li>
  </ul>`,
    },
  ];

  return (
    <div>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="The Mount Kenya University Medical Students' Association (MKUMSA) is
      the local organization that accommodates the medical students of Mount
      Kenya University. Its members encompass the medical students currently
      pursuing their course and medical school alumni who are already in the
      professional line."
        />
      </Helmet>
      <NavBar />
      <Hero details={heroDetails} height="75vh" />
      <div className="aboutUs section-padding bodyBg">
        <h2 className="gradient-text underline ">About Us</h2>
        {details.map((details, index) => (
          <div key={index}>
            <TextArea details={details} />
          </div>
        ))}

        <h2 className="gradient-text underline ">Leadership</h2>
        <div className="aboutUs-leaders">
          {leaders.map((leader, index) => (
            <div key={index}>
              <ProfileCard leader={leader} />
            </div>
          ))}
        </div>
        <TextArea details={history} />

        <h2 className="gradient-text underline ">Past Leadership</h2>
        <Accordion details={previousLeaders} />
      </div>

      <Footer />
    </div>
  );
}

export default AboutUs;
