import React from "react";
import {
  Accordion,
  Hero,
  ImageView,
  NavBar,
  ProfileCard,
  TextArea,
} from "../../components";
import { Footer } from "../../containers";

import colors from "./colors";

import "./sCommittee.css";
import { Helmet } from "react-helmet";
import images from "../../assets/images";

function SCOPH(props) {
  const heroDetails = {
    color: colors.scoph,
    title: "Public Health (SCOPH) ",
    description: `The Standing Committee on Public Health (SCOPH) brings together medical students from all over the world to learn, build skills, cooperate, explore and share ideas when it comes to addressing all issues related to public health, including global health issues, health policies, health promotion and education, activities.`,
  };

  const content = [
    {
      title: "Vision",
      text: `Medical Students attain the optimal skills and knowledge to contribute to their full potential towards the making of healthier communities in their capacity as medical students and as future healthcare providers.`,
    },

    {
      title: "Objectives",
      text: `Disease prevention within our society.
</br>
      Health promotion and education within our society.
      </br>
      Raising awareness about global public health issues within medical students and our society.
      </br>
      Advocating for health policies as the voice of worldwide medical students.
      </br>
      Developing skills and knowledge of medical students as the future health professionals.
      </br>
      Working as an international team and collaborating with external public health organizations to use the potential of over one million worldwide medical students.
      </br>
      Collaborate with other fields as medical education, human rights, and reproductive health.`,
    },

    {
      title: "Main topics",
      text: `Health Promotion: recognized as the key element for community health change. SCOPHians perform different activities to raise awareness, educate and train general population on several health issues to prevent from Communicable to noncommunicable Diseases, to adopt healthy lifestyles addressing risk factors but also caring about the environment.`,
    },
  ];

  const galleryImages = [
    {
      image: images.scoph1,
      description: "scoph",
    },
    {
      image: images.scoph2,
      description: "the research team",
    },
  ];

  const leaders = [
    {
      name: "Edna Kerubo",
      role: "Local Officer",
      email: "ednakerubo667@gmail.com",
      linkedin:
        "https://www.linkedin.com/in/kerubo-orina-9ab86a236?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      picture: images.kerubo,
    },
    {
      name: "Job Gichuru",
      role: "Assistant Local Officer",
      email: "",
      linkedin: "",
      picture: images.job,
    },
  ];

  const previousLeaders = [
    { title: "2023", content: "Caren Imbuhila" },
    { title: "2022", content: "Fred Mugambi" },
    { title: "2021", content: "Raymond Mutinda" },
  ];
  return (
    <div>
      <Helmet>
        <title>SCOPH</title>

        <meta name="description" content={heroDetails.description} />
      </Helmet>
      <NavBar />
      <Hero details={heroDetails} />
      <div className="specificCommittee bodyBg section-padding">
        {content.map((details, index) => (
          <div key={index}>
            <TextArea details={details} />
          </div>
        ))}

        <h2 className="underline gradient-text">Leadership</h2>
        <div className="leaders-container">
          {leaders.map((leader, index) => (
            <div key={index}>
              <ProfileCard leader={leader} />
            </div>
          ))}
        </div>

        <h2 className="underline gradient-text">Gallery</h2>
        <div className="image-container">
          {galleryImages.map((details, index) => (
            <div key={index}>
              <ImageView details={details} />
            </div>
          ))}
        </div>

        <h2 className="underline gradient-text">Previous Leaders</h2>

        <Accordion details={previousLeaders} />
      </div>

      <Footer />
    </div>
  );
}

export default SCOPH;
